<template>
  <div id="zan-container">
    <img class="zan-main" src="../images/zan.png" @click="clickAction">
  </div>
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import {
  fetchZanAdd
} from '@/views/api'
import { isMobileDevice } from '@/util'

const emit = defineEmits(['zanAction', 'zanSuccess'])
const count = ref(0)

// 0未开始 1开始 2结束
const mobileStatus = ref(0)
let timer = null

onMounted(()=>{
  nextTick(()=>{
    addOvserver()
  })
})
onBeforeUnmount(()=>{
  removeOvserver()
})

function clickAction() {
  count.value += 1  
  createZanElement()
  emit('zanAction')
  // 如果是移动端 需要手动触发鼠标移开
  if (isMobileDevice()) {
    createMobileZanTimer()
  }
}

function createMobileZanTimer() {
  closeMobileZanTimer()
  mobileStatus.value = 1
  timer = setTimeout(()=>{
    fetchZanAddData()
    count.value = 0
  }, 1500)
}
function closeMobileZanTimer() {
  if (timer) {
    mobileStatus.value = 0
    clearInterval(timer)
    timer = null
  }
}

function createZanElement() {
  const el = document.getElementById('zan-container')
  const datav = el.attributes[0]
  const item = document.createElement('div')
  item.setAttribute(datav.name, ''); // 设置新元素的属性，属性名称为 data-v- 后面的值
  item.classList.add("zan-item")
  item.textContent = getRandomValue()
  const animationDuration = 2000 + Math.random() * 1500
  item.style.animationDuration = `${animationDuration}ms, 0.3s`;
  item.style.transform = `rotate(${Math.random() * 80 - 40}deg)`
  el.appendChild(item)

  const animationEnd = ()=>{
    item.removeEventListener('animationend', animationEnd)
    el.removeChild(item)
  }
  item.addEventListener('animationend', animationEnd);
}
function getRandomValue() {
  const zans = ["😀", "🤣", "❤️", "😻", "👏", "🤘", "🤡", "🤩", "👍🏼", "🐮", "🐶", "🎈", "💕", "💓", "💚", "🎉", "🎊", "🏮"]
  const min = 0;
  const max = zans.length - 1;
  const random = Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  return zans[random];
}

// 监听鼠标移入事件
function addOvserver() {
  if (isMobileDevice()) { return }
  const el = document.getElementById('zan-container')
  el.addEventListener('mouseenter', hoverAction)
  el.addEventListener('mouseleave', removeHoverAction)
}

//移除事件
function removeOvserver() {
  if (isMobileDevice()) { return }
  const el = document.getElementById('zan-container')
  el.removeEventListener('mouseenter', hoverAction)
  el.removeEventListener('mouseleave', removeHoverAction)
}


function hoverAction() {
  count.value = 0
  console.log('zan=====', count.value)
}


function removeHoverAction() {
  console.log('zan===== leave', count.value)
  if (count.value === 0) {
    return
  }
  fetchZanAddData()
}

function fetchZanAddData() {
  fetchZanAdd(count.value).then(res=>{
    if (res.code === 200) {
      emit('zanSuccess', res.data)
    }
  })
}


</script>

<style lang="scss" scoped>
#zan-container {
  width: 45px;
  height: 45px;
  position: relative;
  cursor: pointer;
  .zan-main {
    width: 100%;
    height: 100%;
  }
  .zan-main:hover {
    transform: scale(1.1);
  }
  .zan-main:active {
    transform: scale(1.2);
  }
  .zan-item {
    position: absolute;
    top: -20px;
    left: 0;
    animation: move linear, opac 0s linear 1.5s;
    animation-fill-mode: forwards;
    pointer-events: none;
    font-size: 30px;
  }
  @keyframes move {
    100% {
      transform: rotate(0) translate(0, -250px);
    }
  }
  @keyframes opac {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>