<template>
  <el-dialog class="no-header" width="400" top="30vh" v-bind="$props" :show-close="false">
    <div class="card-container" ref="card">
      <img class="icon" :src="uStore.info.icon" alt="">
      <div class="info">
        <div class="info-line" v-for="(item, index) in info" :key="index">
          <img class="info-line-icon" :src="item.icon" alt="">
          <template v-if="item.prop === 'email'">
            <a class="info-line-email" :href="'mailto:' + userInfo[item.prop]">
              <div class="info-line-value ">{{ userInfo[item.prop] }}</div>
            </a>
          </template>
          <template v-else>
            <div class="info-line-value">{{ userInfo[item.prop] }}</div>
          </template>
        </div>
      </div>
      <el-image 
        class="wechat" 
        :src="uStore.info.wechat_icon" 
        :preview-src-list="[uStore.info.wechat_icon]"
      />
      <img
        id="download"
        class="download" 
        src="../images/download.png"
        @click="downloadAction"
      >
    </div>
  </el-dialog>
</template>

<script setup>
import { computed, ref } from 'vue'
import html2canvas from 'html2canvas'
import useUserStore from '@/store/user'

const uStore = useUserStore()
const card = ref()

// const wechat = require('../images/20231020171333.jpg')
const info = [
  {
    icon: require('../images/name.png'),
    prop: 'name'
  },
  {
    icon: require('../images/email.png'),
    prop: 'email'
  },
  {
    icon: require('../images/location.png'),
    prop: 'address'
  }
]

const userInfo = computed(()=>{
  return uStore.info
})

// const userInfo = {
//   name: 'ch',
//   email: 'jhtxchenhao@163.com',
//   location: 'Jinhua/Hangzhou Zhejiang'
// }


function downloadAction() {
  const downloadel = document.getElementById('download')
  downloadel.style.display = 'none'
  html2canvas(card.value, {
    useCORS: true
  }).then(canvas => {
    console.log('-----', canvas)
    const dataURL = canvas.toDataURL('image/png')
    const creatDom = document.createElement('a')
    document.body.appendChild(creatDom)
    creatDom.href = dataURL
    creatDom.download = '名片'
    creatDom.click()
    downloadel.style.display = 'block'
  }).catch(err=>{
    console.log('----error:', err)
    downloadel.style.display = 'block'
  })

}

</script>

<style lang="scss" scoped>
.card-container {
  height: 180px;

  text-align: left;
  position: relative;
  border-radius: 10px;
  background: url('../images/bg.jpg') no-repeat;
  background-size: 100% 100%;
  padding: 15px;
  .icon {
    height: 60px;
    width: 60px;
  }
  .info {
    margin-top: 30px;
    .info-line {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      .info-line-icon {
        flex-shrink: 0;
        width: 16px;
        margin-right: 10px;
      }
      .info-line-value {
        font-size: 15px;
        color: #000;
        font-weight: 500;
      }
      .info-line-email {
        color: rgb(66, 131, 251);
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
  .wechat {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
  .download {
    width: 15px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
  }
}

</style>

<style lang="scss">
.no-header {
  background: transparent !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>

