<template>
  <el-drawer
    class="info-drawer"
    v-bind="$attrs"
    :size="500"
    title="修改信息"
  >
    <el-form 
      ref="userForm"
      :model="form"
      label-position="top"
    >
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="姓名" prop="name" :rules="[{required: true, message: '请输入'}]">
            <el-input v-model="form.name" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="欢迎语" prop="welcome">
            <el-input v-model="form.welcome" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="头像">
            <image-upload v-model="form.icon" :limit="1"/>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="微信">
            <image-upload v-model="form.wechat_icon" :limit="1"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <div style="flex: auto">
        <el-button @click="cancelClick">取消</el-button>
        <el-button type="danger" @click="exitAdminClick">退出管理模式</el-button>
        <el-button type="primary" @click="confirmClick">确定</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
import { ref, useAttrs, watch } from "vue"
import {
  fetchUserInfoEdit
} from '../api'
import useUserStore from '@/store/user'
import { ElMessage } from "element-plus"
import { exitAdmin } from "@/admin"

const emit = defineEmits()
const attrs = useAttrs()
const uStore = useUserStore()
const userForm = ref()
const form = ref({})
// form.value = uStore.info
watch(()=>uStore.info,(e)=>{
  console.log('======', e)
  form.value = JSON.parse(JSON.stringify(e))
})
watch(()=>attrs.modelValue,(e)=>{
  if (e) {
    form.value = JSON.parse(JSON.stringify(uStore.info))
  }
})


function saveData() {
  console.log('====', form.value)
  fetchUserInfoEdit(form.value).then(res=>{
    if (res.code === 200) {
      ElMessage.success('修改成功')
      uStore.info = form.value
      close()
    }
  })
}

function exitAdminClick() {
  exitAdmin()
  close()
}

function cancelClick() {
  close()
}

function confirmClick() {
  userForm.value.validate().then(res=>{
    saveData()
  })
}

function close() {
  emit('update:modelValue', false)
}

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.info-drawer {
  text-align: left;
  color: #333;
  font-weight: 500;
  .el-drawer__title {
    font-weight: bold;
  }
  .el-drawer__header {
    margin-bottom: 10px;
  }
}
</style>