<template>
  <router-view v-slot="{ Component }">
    <Particles 
      id="tsparticles" 
      :particlesInit="particlesInit"
      :options="options"
      v-if="true"
    />
      <!-- <transition name="fade" mode="out-in">
        <component :is="Component" v-if="route.meta.keepAlive"/>
      </transition> -->
      
      <component class="children" :is="Component" />

      <!-- <transition name="fade" mode="out-in" v-if="!route.meta.keepAlive">
        <component class="children" :is="Component" :key="route.name" />
      </transition>
      <transition name="fade" mode="out-in" v-if="route.meta.keepAlive">
        <keep-alive>
          <component class="children" :is="Component" :key="route.name"/>
        </keep-alive>
      </transition> -->
  </router-view>
</template>

<script setup>
import { loadFull } from "tsparticles"
import config from './particles'
import firework from './firework'
import { useRoute } from "vue-router"
import useUserStore from './store/user'

const options = config
const route = useRoute()
const particlesInit = async engine => {
    await loadFull(engine);
};
const ustore = useUserStore()

ustore.getUserInfo()



</script>

<style lang="scss">
#app {
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  width: 100vw;
  height: 100vh;
  .children {
    width: 100%;
    height: 100%;
  }
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
