import axios from 'axios'
import { enterAdmin } from '@/admin'

import { ElMessage } from 'element-plus'
let _axios = axios.create({
  timeout: 200000,
  baseURL: '/api',
})


// request
_axios.interceptors.request.use(config=>{
  const token = localStorage.getItem('token')
  if (token) {
    config.headers['token'] = token
  }
  return config
})

// response
_axios.interceptors.response.use(
  (result)=>{
    if (result.status !== 200) {
      return Promise.reject()
    }
    const data = result.data
    if (data.code === 500) {
      ElMessage.error(data.msg)
    } else if (data.code === 401) {
      // 弹出验证框
      return new Promise((resolve, reject)=>{
        enterAdmin().then(()=>{
          // 重新请求
          const config = result.config
          restartRequest(config).then(res=>{
            if (res.code === 200) {
              resolve(res)
            }
          })
        }).catch(()=>{
          reject(data)
        })
      })
    }
    return data
  },
  (error)=>{
    return Promise.reject(error)
  }
)

function restartRequest(config) {
  return _axios(config)
}


export default _axios