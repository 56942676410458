import { defineStore } from 'pinia'
import {
  fetchUserInfo
} from '@/views/api'

const useUserStore = defineStore('user', {
  state: ()=>({
    info: {}
  }),
  actions: {
    getUserInfo() {
      fetchUserInfo().then(res=>{
        if (res.code === 200) {
          this.info = res.data
        }
      })
    }
  }
})

export default useUserStore