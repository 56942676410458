<template>
  <div class="zanview">
    <img class="zan-icon" src="../images/heart.png" alt="">
    <span class="zan-count">{{countStr}}</span>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import {
  fetchZanTotal
} from '@/views/api'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  }
})

const count = ref(props.modelValue)
const emit = defineEmits(['update:modelValue'])

const countStr = computed(()=>{
  if (count.value < 999) {
    return count.value
  } else if (count.value < 9999) {
    return `${parseFloat((count.value / 1000).toFixed(1))}k`
  } else if (count.value < 99999999) {
    return `${parseFloat((count.value / 10000).toFixed(1))}w`
  } else if(count.value < 99999999999) {
    return `${parseFloat((count.value / 100000000).toFixed(1))}亿`
  } else {
    return '100亿+'
  }
})

watch(()=>props.modelValue,(e)=>{
  count.value = e
})
watch(count,(e)=>{
  emit('update:modelValue',e)
})

function fetchZanTotalData() {
  fetchZanTotal().then(res=>{
    if (res.code === 200) {
      count.value = res.data
    }
  })
}

fetchZanTotalData()



</script>

<style lang="scss" scoped>
.zanview {
  display: flex;
  align-items: center;
  .zan-icon {
    height: 20px;
    width: 20px;
  }
  .zan-count {
    margin-left: 5px;
    font-weight: bold;
    font-family: monospace;
  }
}
</style>