<template>
  <div class="component-upload-image">
    <el-upload
      list-type="picture-card"
      :limit="limit"
      ref="imageUpload"
      :show-file-list="true"
      v-model:file-list="fileList"
      :class="{ hide: fileList.length >= limit }"
      :on-preview="handlePictureCardPreview"
      :accept="accept"
      :http-request="uploadHandler"
      :before-upload="handleBeforeUpload"
      :before-remove="handleDelete"
    >
      <el-icon class="avatar-uploader-icon"><plus /></el-icon>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileSize">
        大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
      </template>
      <template v-if="fileType">
        格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
      </template>
      的文件
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="预览"
      width="800px"
      append-to-body
    >
      <img
        :src="dialogImageUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue"
import { Plus } from '@element-plus/icons-vue'
import {
  fetchUpload
} from '@/views/api'
import { ElMessage } from "element-plus";

const props = defineProps({
  modelValue: [String, Object, Array],
  // 图片数量限制
  limit: {
    type: Number,
    default: 5,
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 5,
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ["png", "jpg", "jpeg"],
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
  // 上传文件格式
  accept: {
    type: String,
    default: "image/*"
  },
  // 是否存储在临时目录
  template: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits();
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const fileList = ref([]);
const showTip = computed(
  () => props.isShowTip && (props.fileType || props.fileSize)
);


onMounted(()=>{
  if (props.limit === 1) {
    fileList.value = props.modelValue ? [{url: props.modelValue}] : []
  } else {
    fileList.value = props.modelValue.map(url=>{ return {url: url} })
  }
})

// 预览
function handlePictureCardPreview(file) {
  dialogImageUrl.value = file.url;
  dialogVisible.value = true;
}

// 上传前loading加载
function handleBeforeUpload(file) {
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize;
    if (!isLt) {
      ElMessage.error(`上传文件大小不能超过 ${props.fileSize} MB!`);
      return false;
    }
  }
}

// 上传图片
function uploadHandler(config) {
  fetchUpload(config.file, props.template).then(res=>{
    if (props.limit === 1) {
      if (res.code !== 200) {
        fileList.value = []
        emit('update:modelValue', '')
      } else {
        fileList.value = [{url: res.data, upload: 1}]
        emit('update:modelValue', res.data)
      }
    } else {
      const list = fileList.value.filter(item=>item.upload===1)
      if (res.code === 200) {
        list.push({url: res.data, upload: 1})
      }
      fileList.value = list
      emit('update:modelValue', list.map(item=>item.url))
    }
  })
}

// 删除图片
function handleDelete(file) {
  if (props.limit === 1) {
    emit('update:modelValue', '')
  } else {
    const list = fileList.value.filter(item=>item.uid !== file.uid)
    emit('update:modelValue', list.map(item=>item.url))
  }
}


</script>

<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
:deep(.hide .el-upload--picture-card) {
    display: none;
}
</style>