import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Particles from "vue3-particles"
import VueKinesis from "vue-kinesis";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"

const app = createApp(App)

app.component('ImageUpload', ImageUpload)

// 定义一个全局指令
app.directive('append-to-body', {
  mounted(el) {
    document.body.appendChild(el);
  },
  unmounted(el) {
    if (el) {
      document.body.removeChild(el);
    }
  },
});

app
.use(store)
.use(router)
.use(ElementPlus)
.use(Particles)
.use(VueKinesis)
.use(mavonEditor)
.mount('#app')
