import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      keepAlive: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/articleList',
    redirect: '/articleList/0'
  },
  {
    path: '/articleList/:id',
    name: 'articleList',
    component: ()=> import(/* webpackChunkName: "article" */ '../views/article/ArticleList.vue'),
    children: [
      {
        path: 'empty',
        name: 'articleList/empty',
        component: ()=> import(/* webpackChunkName: "article" */ '../views/article/ArticleEmpty.vue'),
      },
      {
        path: 'file/:articleId',
        name: 'articleList/file',
        component: ()=> import(/* webpackChunkName: "article" */ '../views/article/ArticleView.vue'),
      },
      {
        path: 'default/:directoryId',
        name: 'articleList/default',
        component: ()=> import(/* webpackChunkName: "article" */ '../views/article/ArticleEmpty.vue'),
      },
      {
        path: 'edit/:articleId',
        name: 'articleList/edit',
        component: ()=> import(/* webpackChunkName: "article" */ '../views/article/ArticleEdit.vue'),
      }
    ]
  },
  {
    path: '/articleEdit',
    name: 'articleEdit',
    meta: {
      keepAlive: true
    },
    component: ()=> import(/* webpackChunkName: "article" */ '../views/article/ArticleEdit.vue')
  },
  {
    path: '/toolList',
    name: 'toolList',
    meta: {
      keepAlive: true
    },
    component: ()=> import(/* webpackChunkName: "article" */ '../views/tool/ToolList.vue')
  },
  {
    path: '/servicePublic',
    name: 'servicePublic',
    component: ()=> import(/* webpackChunkName: "service" */ '../views/service/ServicePublic.vue')
  }
]

const router = createRouter({
  history: createWebHistory('/personal'),
  routes
})

export default router
