import axios from "@/axios";

// MARK: danmaku

export function fetchDanmakuAdd(txt) {
  return axios({
    url: '/danmaku/add',
    method: 'post',
    data: {
      str: txt
    }
  })
}

export function fetchDanmakuAll() {
  return axios({
    url: '/danmaku/all'
  })
}


// MARK: user

export function fetchUserInfo() {
  return axios({
    url: '/user/info'
  })
}

export function fetchUserInfoVerify(pwd) {
  return axios({
    url: '/user/verify',
    params: {
      pwd
    }
  })
}

export function fetchUserInfoEdit(data) {
  return axios({
    url: '/user/info',
    method: 'put',
    data
  })
}


// MARK: base

export function fetchUpload(file, tmp=false) {
  const formData = new FormData()
  formData.append('file', file)
  return axios({
    url: `/base/upload?tmp=${tmp}`,
    data: formData,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function fetchDelete() {

}

// markdown
export function fetchCatalogue() {
  return axios({
    url: '/markdown/catalogue/all'
  })
}

export function fetchCatalogueAdd(data) {
  return axios({
    url: '/markdown/catalogue/add',
    method: 'post',
    data
  })
}

export function fetchCatalogueUpdate(data) {
  return axios({
    url: '/markdown/catalogue/update',
    method: 'put',
    data
  })
}

export function fetchCatalogueDelete(id) {
  return axios({
    url: '/markdown/catalogue/delete',
    method: 'delete',
    params: {
      id
    }
  })
}

export function fetchCatalogueDetail(id) {
  return axios({
    url: '/markdown/catalogue/detail',
    params: {
      id
    }
  })
}

export function fetchMarkdownDetail(id) {
  return axios({
    url: '/markdown/article/detail',
    params: {
      id
    }
  })
}

export function fetchMarkdownAdd(data) {
  return axios({
    url: '/markdown/article/add',
    method: 'post',
    data
  })
}

export function fetchMarkdownUpdate(data) {
  return axios({
    url: '/markdown/article/save',
    method: 'put',
    data
  })
}

export function fetchMarkdownMove(data) {
  return axios({
    url: '/markdown/article/move',
    method: 'put',
    data
  })
}

export function fetchMarkdownDelete(id) {
  return axios({
    url: '/markdown/article/delete',
    method: 'delete',
    params: {
      id
    }
  })
}

// ======= zan
export function fetchZanTotal() {
  return axios({
    url: '/zan/count'
  })
}

export function fetchZanAdd(count) {
  return axios({
    url: '/zan/add',
    method: 'post',
    data: {
      count
    }
  })
}


// ======= scrpit
export function fetchScriptChannelCode() {
  return axios({
    url: '/script/connectCode'
  })
}

export function fetchScriptExec(path, key) {
  return axios({
    url: `/script/exec/${path}`,
    params: {
      key
    }
  })
}