<template>
  <div class="home" id="main-home">
    <vue-danmaku 
      class="danmaku" 
      ref="danmakuRef"
      :danmus="[]" 
      randomChannel
      :speeds="100"
      :channels="0"
      :right="100"
    />

    <kinesis-container class="kiness-container" :event="isMobileDevice() ? 'scroll' : 'move'">

      <!--  title -->
      <div class="main-title">
        <template v-for="(item, index) in mainTitle" :key="index">
          <kinesis-element :strength="item.strength" :type="item.type">
            <div class="kinesis-item" :style="item.style">{{ item.value }}</div>
          </kinesis-element>
        </template>
      </div>

      <transition name="danmusShow">
        <div class="danmaku-send" v-show="danmusShow">
          <el-input class="danmaku-input" v-model="msg" placeholder="调侃一下吧～" @keyup.enter.native="sendDanmakuAction" >
            <template #append>
              <el-button :icon="Promotion" @click="sendDanmakuAction" />
            </template>
          </el-input>
        </div>
      </transition>
      <div>
        {{ `Welcom to ${uStore.info.name}'s personal website` }}
      </div>

      <kinesis-element :strength="5" type="rotate" :originX="100" class="welcome-ch">
        {{ uStore.info.welcome || '' }}
      </kinesis-element>
      <!-- <button @click="goabout">goabout</button> -->

      <!-- content -->

      <!--  -->
      <div class="links">
        <template v-for="(item, index) in links" :key="index">
          <zan v-if="item.id === 4" class="link-item-zan" @zanAction="zanAction" @zanSuccess="zanSuccess"/>
          <img v-else class="link-item" :src="item.pic" @click="golink(item)">
        </template>
      </div>


    </kinesis-container>


    <!-- 右上角 -->
    <div class="right-top-tools">
      <img 
        class="tool-item danmaku-switch"
        :src="danmusShow ? require('./images/dm_open.png') : require('./images/dm_close.png')"
        @click="danmakuSwitchAction"
      >
      <img 
        class="tool-item setting"
        src="./images/setting.png"
        @click="settingAction"
      >
    </div>

    <!-- 左上角 -->
    <zan-view class="left-top-views" v-model="count" />


    <!-- 备案号 -->
    <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023052660号-1</a>
    

    <card v-model="cardShow" />
    <user-info v-model="infoShow" />
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router"
import { KinesisContainer, KinesisElement} from 'vue-kinesis'
import { nextTick, onActivated, onDeactivated, onMounted, onUnmounted, ref } from "vue"
import Card from './components/Card.vue'
import UserInfo from './components/UserInfo.vue';
import vueDanmaku from 'vue3-danmaku'
import { Promotion } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from "element-plus"
import {
  fetchDanmakuAll,
  fetchDanmakuAdd
} from './api'
import useUserStore from '@/store/user'
import {
  judgeIsAdmin,
  enterAdmin
} from '@/admin'
import Zan from './components/Zan.vue'
import ZanView from './components/ZanView.vue'
import { isMobileDevice } from '@/util'

// 设置 工具 留言 点赞

const router = useRouter()
const route = useRoute()
const uStore = useUserStore()
const cardShow = ref(false)
const infoShow = ref(false)
const count = ref(0)
const mainTitle = [
  {
    value: 'H',
    strength: 10,
    type: 'rotate',
    style: {
      rotate: '-10deg'
    }
  },
  {
    value: 'E',
    strength: 20,
    type: 'translate',
    style: {
      'margin-top': '-10px'
    }
  },
  {
    value: 'L',
    strength: 10,
    type: 'rotate',
    style: {
      rotate: '10deg'
    }
  },
  {
    value: 'L',
    strength: 5,
    type: 'translate',
    style: {
      'margin-top': '5px'
    }
  },
  {
    value: 'O',
    strength: 20,
    type: 'depth'
  },
  {
    value: '',
    strength: 5,
    type: 'rotate'
  },
  {
    value: 'W',
    strength: 3,
    type: 'scale',
  },
  {
    value: 'O',
    strength: 20,
    type: 'rotate'
  },
  {
    value: 'R',
    strength: 5,
    type: 'translate',
    style: {
      'margin-top': '-10px'
    }
  },
  {
    value: 'L',
    strength: 5,
    type: 'rotate',
    style: {
      rotate: '-10deg'
    }
  },
  {
    value: 'D',
    strength: 10,
    type: 'translate'
  },
]
const links = [
  {
    label: '名片',
    pic: require('./images/card.png'),
    id: 1
  },
  {
    label: '文章',
    pic: require('./images/article.png'),
    id: 2
  },
  {
    label: '工具',
    pic: require('./images/tool.png'),
    id: 3
  },
  {
    label: '赞',
    pic: require('./images/zan.png'),
    id: 4
  },
  {
    label: 'github',
    pic: require('./images/github.png'),
    link: 'https://github.com/jhchenhao',
  },
  {
    label: 'gitlee',
    pic: require('./images/gitee.png'),
    link: 'https://gitee.com/chinaCH',
  },
  {
    label: '简书',
    pic: require('./images/jian.png'),
    link: 'https://www.jianshu.com/u/e6588d19b46c',
  }
]

const danmakuRef = ref()
let danmus = []
const msg = ref()
// 定时器
let timer = null
// 当前要发送的下标
let curIdx = 0


// 弹幕是否隐藏
const danmusShow = ref(sessionStorage.getItem('danmusShow') !== 'false')
if (!danmusShow.value){
  nextTick(()=>{
    danmakuRef.value.hide()
  })
}

onActivated(()=>{
})

onDeactivated(()=>{
})

onMounted(()=>{
  createTimer()
})
onUnmounted(()=>{
  stopTimer()
})


console.log('-------- play music')
const audioElement = new Audio();
audioElement.src = '/api/static/song/haiwa.m4a'//require('./play.mp3')
audioElement.loop = true



function goabout() {
  router.push('/about')
}

function golink(item) {
  if (item.id === 1) {
    cardShow.value = true
  } else if (item.id === 2) {
    if(isMobileDevice()) {
      ElMessageBox.confirm('请前往pc端查看详情', {
        confirmButtonText: '确定',
        showCancelButton: false,
      })
      return 
    }
    router.push('/articleList')
  } else if (item.id === 3) {
    router.push('/toolList')
    // audioElement.play();
  } else if (item.id === 4) {
    
  } else {
    window.open(item.link)
  }
}

function danmakuSwitchAction() {
  danmusShow.value = !danmusShow.value
  if (danmusShow.value) {
    danmakuRef.value.show()
    sessionStorage.setItem('danmusShow', 'true')
  } else {
    danmakuRef.value.hide()
    sessionStorage.setItem('danmusShow', 'false')
  }
}

function sendDanmakuAction() {
  if(!msg.value) { return }
  fetchDanmakuAdd(msg.value).then(res=>{
    if (res.code === 200) {
      danmakuRef.value.insert(msg.value)
      ElMessage.success('发送成功')
      danmus.unshift(msg.value)
      msg.value = ''
    }
  })
}

function settingAction() {
  const isAdmin = judgeIsAdmin()
  if (isAdmin) {
    infoShow.value = true
  } else {
    enterAdmin().then(()=>{
      infoShow.value = true
    })
  }
}

function fetchDanmakuData() {
  fetchDanmakuAll().then(res=>{
    if (res.code === 200) {
      danmus = res.data.map(item=>item.str)
      danmus.sort(()=>{
        return Math.random() - 0.5
      })
    }
  })
}

function shootDanmaku() {
  if (danmus.length === 0) { return }

  // 每次随机发送0～20%
  const step = Math.ceil(danmus.length * 0.2)
  const len = Math.floor(Math.random() * step)
  // console.log(`第${curIdx}条，发送${len}条`)

  for(var i = 0; i < len; i++) {
    const txt = danmus[curIdx]
    danmakuRef.value.insert(txt)
    curIdx += 1
    if (curIdx >= danmus.length) {
      curIdx = 0
    }
  }
}

function createTimer() {
  stopTimer()
  timer = setInterval(() => {
    shootDanmaku()
  }, 2000);
}
function stopTimer() {
  if (timer) {
    clearInterval(timer)
    timer = null
  }
}

function zanAction() {
  count.value += 1
}

function zanSuccess(c) {
  count.value = c
}

window.onresize = (()=>{
  danmakuRef.value.resize()
})

console.log('-=-=-=-=-=-=-=-=-=-=', route.meta)

fetchDanmakuData()

</script>

<style lang="scss" scoped>
.danmusShow-enter-from, .danmusShow-leave-to {
  opacity: 0;
}
.danmusShow-enter-to, .danmusShow-leave-from {
  opacity: 1;
}
.danmusShow-leave-active, .danmusShow-enter-active {
  transition: opacity 0.3s;
}

.home {
  // background: #eee;
  width: 100%;
  height: 100%;
  position: relative;
  .danmaku {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: left;
    font-weight: 500;
  }
  .right-top-tools {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    .tool-item {
      width: 35px;
      height: 35px;
      cursor: pointer;
    }
    .danmaku-switch {

    }
  }
  .left-top-views {
    position: absolute;
    left: 15px;
    top: 15px;
  }
  .beian {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #666;
    font-weight: 500;
  }
  .kiness-container {
    touch-action: manipulation;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .main-title {
    display: flex;
    font-family: fantasy;
    font-weight: 900;
    .kinesis-item {
      width: 50px;
      font-size: 50px;
      @media (max-width: 500px) {
        width: 30px;
        font-size: 30px;
      }
    }
  }
  .danmaku-send {
    width: 540px;
    @media (max-width: 500px) {
      width: 300px;
    }
    margin-bottom: 15px;
    .danmaku-input {
      background: transparent;
    }
    ::v-deep{
      .el-input__wrapper {

        background: transparent;
        .el-input__inner {
          font-weight: 500;
          color: white;
        }
        .el-input__inner::placeholder {
          color: #3b3b3b;
        }
      }
      .el-input__wrapper.is-focus {
        box-shadow: 0 0 0 1px white inset;
      }
      .el-input-group__append {
        background: transparent;
        cursor: pointer;
        color: white;
        font-weight: 500;
      }
    }
  }
  .welcome-ch {
    margin-top: 10px;
    font-weight: 500;
  }
  .links {
    margin-top: 20px;
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .link-item {
      width: 30px;
      height: 30px;
      border: 2px solid white;
      border-radius: 15px;
      cursor: pointer;
      transition: transform 0.3s;
    }
    .link-item-zan {
      width: 45px;
      height: 45px;
      border: none;
    }
    .link-item:hover {
      // scale: 1.2;
      transform: scale(1.2);
    }
  }
}
</style>
