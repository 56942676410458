
import { ElMessageBox } from 'element-plus'
import { fetchUserInfoVerify } from '@/views/api'


export function saveAdmin(data) {
  localStorage.setItem('token', data)
}

export function judgeIsAdmin() {
  return localStorage.getItem('token')
}

export function exitAdmin() {
  localStorage.removeItem('token')
}

export function judgeIsAdminAndContinue(action) {
  const isAdmin = judgeIsAdmin()
  if (isAdmin) {
    action()
  } else {
    enterAdmin().then(()=>{
      action()
    })
  }
}

export function enterAdmin() {
  return new Promise((resolve, reject) => {
    ElMessageBox.prompt('请输入密码验证', '进入管理模式', {
      type: 'warning',
      cancelButtonText: '取消',
      confirmButtonText: '确定',
      inputPlaceholder: '请输入',
      inputValidator:(e)=>{
        return e !== null
      },
      inputErrorMessage: '请输入'
    }).then(({value})=>{
      fetchUserInfoVerify(value).then(res=>{
        if (res.code === 200 && res.data) {
          // 验证成功，将token 存入到localstrolage中 重新请求
          saveAdmin(res.data)
          resolve()
        } else {
          // 验证失败
          reject()
        }
      })
    }).catch(()=>{
      reject()
    })
  })
}
